@use "./styles/partials/fonts" as *;
@use "./styles/partials/mixins" as *;
@use "./styles/partials/variables" as *;

/* Reset the color of visited links to be the same as unvisited links */
a:visited {
  color: inherit; /* Inherits the color of the unvisited link */
  text-decoration: none; /* Removes underline */
}

/* Optionally, to ensure unvisited links are also not underlined */
a {
  text-decoration: none;
  color: inherit;
}

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200italic,300,300italic,regular,italic,600,600italic,700,700italic,900,900italic);

@import url(https://fonts.googleapis.com/css?family=Titillium+Web:200,200italic,300,300italic,regular,italic,600,600italic,700,700italic,900);

@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;1,500&family=Montserrat:ital,wght@0,100;0,200;1,300&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Acumin Pro";
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3 {
  margin: 0%;
  padding: 0%;
}
ul {
  list-style: none;
  padding-left: 0;
}

.span{
  &__green{
    color:$gold;
  }
}
