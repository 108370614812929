@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.membership {
  background-color: black;
  color: white;
  font-family: "Glacial Indifference", serif;

  &__hero {
    position: relative;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../assets/images/Membership.jpg");

    background-attachment: fixed;
    background-position-x: 40%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include tablet {
      height: 60vh;
    }
    @include desktop {
      background-position-y: 20%;
      height: 100vh;
    }
  }
  &__title {
    margin-top: 40%;
    font-size: 2.5rem;
    color: white;
    @include tablet {
      margin-top: 20%;
    }
    @include desktop {
      margin-top: 20%;
      letter-spacing: 0.3rem;
      font-size: 3rem;
    }
  }
  &__subtitle {
    padding-top: 10%;
    color: white;
    letter-spacing: 0.5rem;
    @include desktop {
      padding-top: 5%;
    }
  }
  &__button {
    border: black 1px;
  }
  &__span {
    &--gold {
      font-size: 3rem;
      letter-spacing: 0.5rem;
      color: rgba(255, 222, 137);
    }
  }
  &__section {
    @include desktop {
      margin: $desktop;
    }

    &--title {
      font-size: 3rem;
      margin: 4rem;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    margin: 0%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @include desktop {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    &--item {
      width: calc(
        40.33% - 1rem
      ); // Adjust the width for mobile to fit 3 items in a row
      margin: 1rem; // Adjust the margin as needed
      @include desktop {
        width: calc(
          33.33% - 2rem
        ); // Adjust the width for mobile to fit 3 items in a row
        margin: 1rem; // Adjust the margin as needed
      }
    }

    &--title {
      margin: 0%;
      font-size: 0.9rem;
      font-weight: bold;
      color: rgba(255, 222, 137);
      @include tablet {
        font-size: 1rem;
      }
      @include desktop {
        font-size: 1.25rem;
      }
    }
    &--benefits {
      font-size: 0.9rem;
      @include tablet {
        font-size: 1rem;
      }
      @include desktop {
        font-size: 1.2rem;
      }
    }
    &--item {
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      @include tablet {
        width: calc(33.33% - 4rem); // Subtract the total margin from the width
        margin: 2rem; // Adjust the margin as needed
      }
    }
  }
  &__icon {
    color: rgba(255, 222, 137);
  }
  &__button--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__signup {
    margin: 4rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include desktop {
      margin: 8rem 0rem;
    }

    &--header {
      text-transform: capitalize;
      text-align: center;
      font-size: 3rem;
      margin: 3rem 0rem;
      @include desktop {
        font-size: 5rem;
        margin: 7rem 0rem;
      }
    }
    &--button {
      @include desktop {
        margin: 7rem;
        width: 30%;
      }
    }
  }
}
