@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.about {
  background-color: white;
  color: black;
  font-family: "Glacial Indifference", serif;
  &__hero {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../assets/images/aboutus.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include tablet {
      background-position: 80% 60%;
    }
    @include desktop {
      height: 80vh;
    }
  }
  &__title {
    letter-spacing: 0.5rem;
    padding-top: 30%;
    font-size: 3.5rem;
    color: #ffffff;
    @include tablet {
      padding-top: 35%;
    }
    @include desktop {
      padding-top: 20%;
      font-size: 4rem;
    }
  }
  &__subtitle {
    padding-top: 10%;
    color: white;
    @include desktop {
      padding-top: 1%;
    }
  }
  &__button {
    border: black 1px;
  }
  &__span {
    &--gold {
      font-size: 3rem;
      letter-spacing: 0.5rem;
      color: rgba(255, 222, 137);
    }
  }
  &__section {
    padding: $mobile;
    @include desktop {
      padding: 10rem;
    }

    &--title {
      margin-bottom: 3rem;
      font-size: 3rem;
      text-align: center;
      @include desktop {
        margin: 3rem 0rem;
      }
    }
    &--content {
      font-size: 1.3rem;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    &--title {
      margin: 0%;
      font-size: 1.25rem;
      font-weight: bold;
      color: $green;
    }
    &--benefits {
      font-size: 1.2rem;
    }
    &--item {
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      column-gap: 1rem;
      margin: 2rem;
      @include desktop {
        width: calc(25% - 4rem); // Subtract the total margin from the width
        margin: 2rem; // Adjust the margin as needed
      }
    }
  }
  &__icon {
    color: $green;
  }
}

.person {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop {
    flex-direction: row;
  }
  &__name {
    width: 15rem;
    text-align: center;
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  &__icon {
    height: 2rem;
  }
  &__bio {
    font-size: 1rem;
  }
}
