@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.faq {
  margin: 2rem;
  background-color: "black";
  @include tablet {
    margin: 6rem 3rem 10rem;
  }
  @include desktop {
    margin: 0rem 10rem 10rem;
  }
  &__header {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
    letter-spacing: 0.5rem;
    color: rgba(255, 222, 137);
    @include tablet {
      font-size: 3rem;
    }
    @include desktop {
      font-size: 4rem;
      padding-bottom: 3rem;
    }
  }
  &__text {
    font-family: "Glacial Indifference";
  }
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.MuiTypography-root {
  font-family: "Glacial Indifference" !important;
}
