@font-face {
    font-family: "Glacial Indifference";
    src: local("Glacial Indifference Bold"), url("../../assets/fonts/GlacialIndifference-Bold.otf") format("opentype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Glacial Indifference";
    src: local("Glacial Indifference Italic"), url("../../assets/fonts/GlacialIndifference-Italic.otf") format("opentype");
    font-style: italic;
    font-weight: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Glacial Indifference";
    src: local("Glacial Indifference"), url("../../assets/fonts/GlacialIndifference-Regular.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }
  