@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.hr {
  width: 50%;
}

a:visited {
  color: inherit; /* Inherits the color of the unvisited link */
  text-decoration: none; /* Removes underline */
}

a {
  color: inherit;
}

.nav {
  @include mobile {
    display: none;
  }
  padding: 1rem 5rem;
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  display: block;
}
.header {
  font-family: "Glacial Indifference", serif;
  position: sticky;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.25rem;
  top: 0px;
  z-index: 1;
  background-color: white;
  display: none;
  padding: 0;
  color: black;
  flex-direction: row;
  justify-content: space-between;

  @include desktop {
    display: flex;
  }
  &__home {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__logo {
    height: 6rem;
  }
  &__name {
    padding: 0rem;
    font-size: 2rem;
  }

  &__left {
    display: flex;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    vertical-align: middle;
    align-items: center;
  }
  &__menu {
    display: flex; // This ensures the items are in a row
    flex-direction: row; // Explicitly define the direction as a row
    align-items: center; // This vertically aligns all items in the middle
    column-gap: 2.5rem;
    &--item {
      white-space: nowrap;
      vertical-align: middle;
      align-items: center;
    }
    &--memberships {
      color: rgba(223, 190, 106);
      border: 2px solid #3a9e95; // Added solid to make the border visible
      padding: 1rem 1rem;
    }
  }

  &__btn-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &__book {
    color: #3a9e95;
  }
  &__link {
    display: inline-flex;
    justify-content: flex-end;
    align-content: flex-end;
    text-decoration: none;
    font-weight: normal;
    color: #3a9e95;

    &:hover {
      transition-delay: 0.2s;
      color: rgba(223, 190, 106);
      text-decoration: none;
    }

    &.active {
      color: rgb(243, 207, 115);
      text-decoration: none;
    }
  }
  &__item {
    display: inline-block;
    align-self: center;
    font-size: 1rem;
  }
  &__logo {
    font-size: 2rem;
    font-weight: normal;
    justify-content: center;
    align-content: flex-end;
    display: inline-block;
    align-self: center;
    padding: 0%;
    margin: 0%;
  }
  &__button {
    color: black;
  }
  &__sublogo {
    color: rgb(243, 207, 115);
    font-size: 0.7rem;
    align-content: center;
    justify-content: center;
    display: grid;
  }
  &__button {
    padding: 0.625rem 1.875rem;
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  &:hover .dropdown--content {
    display: block;
  }
  &--heading {
    color: rgba(205, 174, 96, 0.7);
    letter-spacing: 0.3rem;
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem 0rem;
  }
  &--content {
    color: black;
    white-space: nowrap;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 10rem;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 4px;
    transition: opacity 0.3s ease;
    .header__link {
      display: block;
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0; // Ensure that the last link doesn't have extra space below it
      }
    }
  }
}

.membership_button,
.membership_btn-container {
  border: 1px solid #3a9e95;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  background: transparent;
  color: #3a9e95;
  border-radius: 0;
  background: linear-gradient(
    270deg,
    rgba(106, 223, 176, 0.8),
    rgba(52, 147, 107, 0.8),
    rgba(34, 34, 34, 0),
    rgba(34, 34, 34, 0)
  );
  background-position: 1% 50%;
  background-size: 300% 300%;
  text-decoration: none;

  transition: all 0.7s ease-in-out;
  @media (max-width: 768px) {
    font-size: 0.5rem;
  }

  // Hover effect for button
  &:hover {
    color: #fff;
    border: 1px solid rgba(106, 223, 196, 0.3);
    background-position: 99% 50%;
    background-color: black;
  }
}
// Container for buttons
.membership_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.membership_btn {
  font-weight: normal;
  text-align: center;
  height: 100%;
  padding: 0.65rem 1.45rem;
}
