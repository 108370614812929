//breakpoints
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1280px;

//margins for tablet and desktop
$mobile:2rem;
$tablet:1.6rem 10.2rem 6rem;
$desktop:10rem;

$green:#3a9e9e;
$gold:rgba(255, 222, 137);