@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include desktop {
    flex-direction: row;
  }
  &__pic {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../assets/images/signup.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 30vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    @include desktop {
      width: 50%;
      background-position-y: 25%;
      height: 100vh;
    }
  }
  &__title {
    font-size: 2rem;
    font-family: "Glacial Indifference", serif;
    font-weight: 200;
    @include desktop {
      font-size: 4rem;
    }
  }
  &__name {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    width: 100%;
    @include desktop {
      column-gap: 2rem;
    }
  }
  &__button {
    width: 50%;
  }
}
.sign-up-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 4rem 2rem;
  color: white; // Text color set to white for better visibility on black background

  @include desktop {
    width: 50%;
    background-position-y: 25%;
    height: 100vh;
  }
  .MuiTextField-root {
    margin-bottom: 16px;
    label {
      color: white; // Label color
    }
    .MuiOutlinedInput-root {
      color: white; // Input text color
      fieldset {
        border-color: white; // Border color
      }
      &:hover fieldset {
        border-color: white; // Border color on hover
      }
      &.Mui-focused fieldset {
        border-color: white; // Border color when the input is focused
      }
    }
  }

  .MuiButton-root {
    margin-top: 16px;
    color: white; // Button text color
    border-color: white; // Button border color
    &:hover {
      background-color: rgba(255, 255, 255, 0.2); // Slight highlight on hover
    }
  }
}

body {
  background-color: black; // Setting the background color to black
}
