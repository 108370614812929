@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.home {
  &__hero {
    font-family: "Glacial Indifference", serif;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../assets/images/Hero1.jpg");
    background-position: 25% 60%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include desktop {
      background-position-y: 10%;
    }
  }
  &__title {
    letter-spacing: 0.5rem;
    padding-top: 30%;
    font-size: 4rem;
    color: #ffffff;
    @include tablet {
      padding-top: 40%;
    }
    @include desktop {
      padding-top: 20%;
    }
  }
  &__subtitle {
    color: #d8d8d8;
  }
  &__button {
    margin-top: 2rem;
    border: black 1px;
    color: white;
  }
}
