@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.newsletter {
  background-color: #325c58;
  padding: 3rem 2rem;
  text-align: center;
  font-family: "Glacial Indifference";
  @include tablet {
    padding: 5rem;
  }
  @include desktop {
    padding: 7rem;
  }

  &__title {
    color: white;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    @include desktop {
      font-size: 3rem;
    }
  }

  &__subtitle {
    color: rgb(215, 215, 215);
    margin-bottom: 2rem;
    font-size: 1rem;
    @include desktop {
      font-size: 1.5rem;
    }
  }

  .newsletter__input-group {
    display: flex;
    justify-content: center;

    input {
      padding: 0.5rem 1rem;
      border: none;
      width: 70%;
      @include desktop {
        width: 30%;
      }
    }

    button {
      font-family: "Glacial Indifference";
      margin-left: 0.5rem;
      padding: 0.5rem 1.5rem;
      border: none;
      background-color: #333;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-family: "Glacial Indifference";
      &:hover {
        background-color: darken(#333, 10%); // Darken the button on hover.
      }
    }
  }
}
