@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.under-construction {
  font-family: "Glacial Indifference", serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: white;
  padding: 2rem;
  padding-top: 5rem;
  @include desktop {
    padding: 10rem;
  }
  h1 {
    font-size: 2em;
    color: #333;
  }

  p {
    font-size: 1em;
    color: #666;
  }
}
